import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout' 
import FooterBranding from '../components/footer/FooterBranding'

const DevenirBenevole = () => (
  <Layout>
      <div className="container custom-container">
          <div className="container-inner">
          
          <div className="split-contact">
                
                <div className="content-wrapper">
                    <div className="content">
                        <h1>FAIRE UN DON</h1>
                        <p>
                        Pour nous soutenir, vous trouverez, ci-dessous, les différentes formes de générosité mises à votre disposition.
                        </p>
                        <h2><i className="icon-don"></i> Dons financiers</h2>  
                        <p>Par virement bancaire sécurisé : <a href="https://www.paypal.me/associationjaide?locale.x=fr_FR" className="btn" ><span>FAIRE UN DON</span>  </a></p>
                        <p>Par chèque à l'ordre de : Association JAIDE à envoyer à l'adresse suivante, 3 chemin du clos - 95 650 Puiseux-Pontoise</p>
                    </div>
                    <div className="content ">
                        <h2><i className="icon-caddie"></i>Autres dons</h2>
                        <p><b>Récoltes de denrées alimentaires et tenues vestimentaires pour les plus démunis</b></p>
                        <p>Tout au long de l'année, nous nous organisons pour apporter un soutien matériel à toute personne en difficulté.</p>
                        <p>Dans le cadre de notre opération "caddie solidaire", nous récoltons chaque mois des denrées alimentaires non périssables ainsi que des produits d'hygiène.</p>
                        <p>L'ensemble de nos récoltes est redistribué aux plus démunis (lors de nos maraudes hebdomadaires et toutes nos autres actions).</p>
                        <p>Pour plus de renseignements : <Link to="/contact" className="btn" ><span>Contactez-nous</span>  </Link></p>

                        <p>Par ailleurs, en lien avec des associations présentes sur le territoire du Val d'Oise (95), des Yvelines (78) ou de l'Oise (60), notamment dans le cadre du « plan grand froid », une récolte de tenues vestimentaires peut être organisée en faveur des plus démunis (personnes sans abris, personnes hébergées en centre d'hébergement, ou autre).</p>
                        <p>Pour plus de renseignements : <Link to="/contact" className="btn" ><span>Contactez-nous</span>  </Link></p>
                        <p><b>Fournitures scolaires</b></p>
                        <p>A l’occasion de chaque rentrée scolaire, nous organisons une collecte de fournitures scolaires en faveur des familles les plus modestes (cahiers, stylos, cartables et autres fournitures scolaires).</p>
                        <p>Si vous souhaitez nous aider et/ou contribuer à cette action, pour plus de renseignements : <Link to="/contact" className="btn" ><span>Contactez-nous</span>  </Link></p>
                    </div>
                    
                </div>
            
            </div>



          </div>
      </div>
    <FooterBranding/>
  </Layout>
)

export default DevenirBenevole
